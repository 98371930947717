<script>
    import {user, activeCase} from "./store";
    import SignIn from "./account/SignIn.svelte";
</script>
{#if $user.signedIn}
    {#if $activeCase}
        <slot></slot>
    {/if}
{:else}
    <SignIn/>
{/if}
