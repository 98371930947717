<script>
    import {activeCase, activeNeeds} from "../store";
    import {formatLoanTerm} from "../utils";
    import {loanPurposes} from "../lists";
    import TrustBox from "../components/TrustBox.svelte";

    $: quoted = $activeCase && $activeCase.product;
    $: title = quoted ? "Loan Approved in Principle" : "We're working on your quote";
    $: subTitle = quoted ? `Great news, you have been accepted for a loan with ${lender}!` : "Please check back later for updates. In the mean time, if you need us you can always call us on 01302 590 873.";
    $: lender = quoted && $activeCase.product.lender ? $activeCase.product.lender : "Believe Loans";
    $: loanTerm = quoted && $activeCase.product.rates ? $activeCase.product.rates.reduce((a, b) => a + b.term, 0) : null;
    $: initialMonthlyPayment = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].monthlyRepayment : null;
    $: initialRate = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].rate : null;
    $: loanPurpose = $activeCase.loanPurposes.length ? loanPurposes[$activeCase.loanPurposes[0]] ? loanPurposes[$activeCase.loanPurposes[0]] : "Other" : "Other";

    $: incompleteNeeds = $activeNeeds.filter(x => x.completed == false);

</script>

{#if !$activeCase}
    <div class="container">
        <p>Loading...</p>
    </div>
{:else}
    <div class="welcome">
        {#if incompleteNeeds.length > 0 }
            <div class="container">
                <a href="/todo" class="needs__banner">
                    <h2>Incomplete To-Do List</h2>
                    <p>There are items on your to-do list, click here to complete them.</p>
                </a>
            </div>
        {/if}
        <div class="container">
            <h1>{title}</h1>
            <h3>{subTitle}</h3>
        </div>
        {#if quoted}
            <div class="terms">
                <div class="container">
                    <div>
                        <span>Borrowing</span>
                        <p>&pound;{ $activeCase.product.loanAmount.toLocaleString("en-GB") }</p>
                    </div>
                    <div>
                        <span>Purpose</span>
                        <p class="capitalize">{ loanPurpose }</p>
                    </div>
                    <div>
                        <span>Term</span>
                        <p>{ formatLoanTerm(loanTerm) }</p>
                    </div>
                </div>
            </div>
            <div class="container">
                <h2>Summary of your Agreement in Principle:</h2>
            </div>
            <div class="terms">
                <div class="container">
                    {#if lender !== "Believe Loans"}
                        <div>
                            <span>Lender</span>
                            <p>{ lender }</p>
                        </div>
                    {/if}
                    <div>
                        <span>Initial Monthly Payment</span>
                        <p>
                            &pound;{
                        initialMonthlyPayment.toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })
                        }
                        </p>
                    </div>
                    <div>
                        <span>Initial Interest Rate</span>
                        <p>
                            {
                            initialRate.toLocaleString("en-GB", {
                            maximumFractionDigits: 2
                            })
                            }%
                        </p>
                    </div>
                    <div>
                        <span>Provisionally Accepted by Lender</span>
                        <p>Yes</p>
                    </div>
                </div>
            </div>
        {/if}
        {#if quoted}
            <div class="container next-steps">
                <h2>Next Steps</h2>
                <p>
                    If you would like us to proceed with this quote, let us know and return
                    the requested documentation. We will then take it from there for you.
                    You can call us on <strong>01302 590 873</strong>.
                </p>
                <p>
                    <a class="call" href="tel:+441302590873">
                        <svg
                                width="24"
                                height="24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="white"
                        >
                            <path
                                    d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.639.855-2.313 2.666-2.289 4.916.075 6.948 6.809 18.071 12.309 18.045.541-.003 1.07-.113 1.58-.346.121-.055 2.102-1.029 2.11-1.033zm-2.5-13.621c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm9 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-4.5 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                            />
                        </svg>
                        <span>Call Us</span>
                    </a>
                </p>
            </div>
        {/if}
        <TrustBox/>
        {#if quoted}
            <footer>
                <div class="container">
                    <p>
                        This Loan in Principle does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. This is only an indicative quote and advice specific to your needs & circumstances will be given following a full application and prior to receiving the lenders mortgage offer.
                    </p>
                    <p>
                        <strong
                        >Your home may be repossessed if you do not keep up repayments on
                            your mortgage.</strong
                        >
                    </p>
                </div>
            </footer>
        {/if}
    </div>
{/if}

<style>

    .needs__banner {
        background-image: linear-gradient(to bottom, #DB214C 0%, #853050 100%);
        border: 1px solid #853050;
        display: block;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: bold;
        padding: 1em 0;
        border-radius: 0.4em;
        margin-top:1rem;
    }

    .needs__banner:hover {
        background-image: linear-gradient(to bottom, #853050 0%, #DB214C 100%);
    }

    .needs__banner h2,
    .needs__banner p {
        padding:0.5rem;
        margin:0;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .welcome {
        text-align: center;
    }

    .terms {
        background-color: #d5f0f5;
    }

    .terms > .container {
        display: flex;
        padding: 0;
    }

    .terms > .container > div {
        flex: 1;
        padding: 0.5em;
        border-left: 1px solid #abe1ec;
    }

    .terms > .container > div:last-child {
        border-right: 1px solid #abe1ec;
    }

    .terms > .container > div > span {
        font-weight: bold;
        color: hsl(190, 64%, 30%);
        display: block;
        margin: 1em 0;
    }

    .terms > .container > div > p {
        color: #124853;
        font-size: 1.75em;
    }

    .steps {
        padding: 3em 0;
        background-color: #ffffff;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
    }

    .steps > .container {
        display: flex;
        align-items: flex-start;
        position: relative;
    }

    .steps > .container > :global(div) {
        flex: 1;
        z-index: 1;
    }

    .steps > .container::before {
        position: absolute;
        top: 45px;
        height: 10px;
        left: 8.333%;
        right: 8.333%;
        background-color: #124853;
        content: "";
    }

    .next-steps {
        margin-bottom: 2em;
    }

    .next-steps > div {
        margin-top: 2em;
    }

    .call {
        display: none;
        border-radius: 4px;
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        border: 1px solid #348f96;
        color: white;
        padding: 0.5em;
        text-decoration: none;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    }

    .call > span {
        margin-left: 0.5em;
    }

    .reviews {
        padding-bottom: 1.5em;
    }

    footer {
        background-color: #ffffff;
        padding: 1em;
        border-top: 1px solid #cccccc;
    }

    @media screen and (max-width: 1236px) {
        .terms > .container {
            padding: 0;
        }
    }

    @media screen and (max-width: 767px) {
        .terms > .container {
            flex-direction: column;
        }

        .terms > .container > div {
            border-left: none;
            border-top: 1px solid #abe1ec;
        }

        .terms > .container > div:last-child {
            border-bottom: 1px solid #abe1ec;
        }

        .steps {
            padding: 1em 0;
        }

        .steps > .container {
            flex-direction: column;
            align-items: center;
        }

        .steps > .container > :global(div) {
            margin: 0.5em 0;
        }

        .steps > .container::before {
            height: 83.333%;
            width: 10px;
            top: 8.333%;
            left: calc(45px + 1em);
        }

        .steps :global(.quote-step) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }

        .steps :global(.quote-step > svg) {
            min-width: 100px;
        }

        .steps :global(.quote-step > span) {
            margin-left: 1em;
            text-align: left;
        }

        .call {
            display: flex;
        }
    }
</style>