<script>
    import { router } from "tinro";
    import api from "../api";

    let email = "", error = "", done = false, working = false;

    async function reset() {
        error = "";
        if (!email) {
            error = "Please enter your e-mail address";
            return;
        }
        if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email.trim())) {
            error = "Please enter a valid e-mail address";
            return;
        }
        working = true;
        const result = await api.reset(email);
        working = false;
        if (!result.success) {
            error = result.error;
            return;
        }
        done = true;
    }

    function home() {
        router.goto("/");
    }

    function handleEnter(e) {
        if (!working && e.key === "Enter") {
            reset();
        }
    }
</script>
<div class="container">
    {#if !done}
        <h1>Reset your Account</h1>
        <p>
            If you're having trouble accessing your case, please enter your e-mail address below and we will reset it.
        </p>
        <div class="form">
            <label for="email">E-mail address:</label>
            <input id="email" bind:value={email} on:keyup={handleEnter}/>
            <button on:click={reset} disabled={working}>Reset Account</button>
            <span class="error">{error}</span>
        </div>
    {:else}
        <h1>Thank You</h1>
        <p>
            If {email} matches an account on our system, we have sent you an e-mail with instructions on how to complete
            resetting your account.
        </p>
        <p>
            <button on:click={home}>Home</button>
        </p>
    {/if}
</div>