<script>
    import {router} from "tinro";
    import {activeCase, activeNeeds} from "../store";
    import Needs from "../components/Needs.svelte";
    import Upload from "..//components/Upload.svelte";
    $: needs = $activeNeeds;
</script>

<div class="container">
    <h1>My To-Do List</h1>
    <Needs/>
    {#if needs.filter(x => x.type == "need" && x.completed == false).length == 0 }
        <Upload class="upload" on:uploaded={(e) => {
                setTimeout(() => {
                    // delay so docs load
                    router.goto("/documents");
                }, 500);
            }
        } />
    {/if}
</div>
