import {get} from "svelte/store";
import {user, activeCase, activeNeeds, signOut} from "./store";
import { aipAccessCode } from "./utils";
import api from "./api"

let token = null;
let customerName = null;
let accountId = null;
let caseId = null;

user.subscribe(async (user) => {
  if (user.signedIn) {
    token = user.token;
    accountId = user.accountId;
    customerName = user.profile.name;
    const cases = await authedRequest(`/api/${user.accountId}`);
    activeCase.set(cases?.sort((a, b) => b.reference - a.reference)[0]);
  } else {
    token = null;
    customerName = null;
  }
});

activeCase.subscribe(async(c) => {
  caseId = c ? c.id : null;
  if (c) {
    await fetchNeeds();
  } else {
    activeNeeds.set([]);
  }

});

async function fetchNeeds() {
  if (api == undefined) return;
  // fetch needs
  let n = await api.needs();
  // tag them
  n = n.map(need => {return {
    ...need,
    type: "need"
  }});
  // add esigns
  let esigns = await api.pendingESigns();
  esigns.forEach(x => {
    n.unshift(
     {
      id: null,
      completed: false,
      description: x.name,
      notes: "Ready for signature",
      type: "esign",
       pack: x
    });
  });

  // add smart search
  const smartSearchComplete = sessionStorage.getItem("smartSearchComplete") === "yes";
  let ac = get(activeCase);
  if (ac.smartSearch != undefined && !smartSearchComplete) {
    n.unshift({
      id: null,
      completed: false,
      description: "Confirm your identity",
      notes: "Provide identity document",
      type: "smartsearch"
    });
  }
  n.sort((a,b) => {
    if (a.completed == b.completed) {
      if (a.type == b.type) {
        return a.description.localeCompare(b.description);
      }
      return a.type - a.type;
    }
    return a.completed - b.completed
  });
  activeNeeds.set(n);
}


async function authedRequest(url, opts = {}, defaultFn = () => null) {
  opts.headers = opts.headers || {};
  opts.headers.Authorization = `Bearer ${token}`;
  const result = await fetch(url, opts);
  if (result.status === 401) {
    signOut();
    return defaultFn();
  }
  try {
    return await result.json();
  } catch {
    return defaultFn();
  }
}

export default {
  async signIn(email, password) {
    try {
      const result = await fetch("/api/sign-in", {
        method: "POST",
        body: JSON.stringify({email, password})
      });
      if (result.status === 200) {
        const detail = await result.json();
        return {...detail, success: true};
      } else if (result.status === 401) {
        return {success: false, error: "Invalid username / password combination"};
      } else {
        return {success: false, error: "Unable to sign in right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to sign in right now. Please try again later"};
    }
  },
  async reset(email) {
    try {
      const result = await fetch("/api/reset", {
        method: "POST",
        body: JSON.stringify({email})
      });
      if (result.status === 200) {
        return {success: true};
      } else {
        return {success: false, error: "Unable to reset your account right now. Please try again later"};
      }
    } catch {
      return {success: false, error: "Unable to sign in right now. Please try again later"};
    }
  },
  async checkInvite(code) {
    try {
      const result = await fetch(`/api/check/${code}`);
      if (result.status === 200) {
        return await result.json();
      } else {
        return false;
      }
    } catch {
      return false;
    }
  },
  async redeemInvite(code, password) {
    try {
      const result = await fetch('/api/activate', {method: "POST", body: JSON.stringify({"invitationCode": code, password})});
      if (result.status === 200) {
        const detail = await result.json();
        return {...detail, success: true};
      } else {
        return {success: false};
      }
    } catch {
      return {success: false};
    }
  },
  async eSignByCode(documentCode, signerCode) {
    try {
      const result = await fetch(`/api/e-sign/${documentCode}/${signerCode}`);
      if (result.status === 200) {
        const redirect = await result.json();
        return {redirect, success: true};
      } else {
        let error;
        try {
          const detail = await result.json();
          error = detail.error || "Unexpected error";
        } catch {
          error = "Unexpected error";
        }
        return {success: false, error};
      }
    } catch {
      return {success: false, error: "Unexpected error"};
    }
  },
  needs(outbound) {
    return authedRequest(`/api/${accountId}/case/${caseId}/needs`, {}, () => []);
  },
  needCompleted(needId, documents) {
    console.log(needId, documents)
    return authedRequest(`/api/${accountId}/case/${caseId}/needs/${needId}`,
        {
          method: "POST",
          body: JSON.stringify({ documents: documents })
        });
  },
  documents(outbound) {
    return authedRequest(`/api/${accountId}/case/${caseId}/documents/${outbound ? "outbound" : "inbound"}`, {}, () => []);
  },
  smartSearch(id, payload) {
    return authedRequest(`/api/${accountId}/case/${caseId}/smart-search/${id}`,
        {
          method: "POST",
          body: JSON.stringify(payload)
        });
  },
  returnedFromSmartSearch(id) {
    return authedRequest(`/api/${accountId}/case/${caseId}/smart-search/${id}`,
        {
          method: "PUT"
        });
  },
  pendingESigns() {
    return authedRequest(`/api/${accountId}/case/${caseId}/e-sign`, {}, () => []);
  },
  proceedToSign(packId) {
    return authedRequest(`/api/documents/e-sign/${packId}`, {method: "POST"});
  },
  upload({ file, name, progress }) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        } else if (xhr.status === 401) {
          signOut();
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({success: false}));
      xhr.open("POST", `/api/${accountId}/case/${caseId}/documents?fileName=${file.name}&displayName=${name || file.name}`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  },
  async aipStatus(accessCode) {
    try {
      const result = await fetch(`/api/aip/${accessCode}`);
      if (result.status === 200) {
        const detail = await result.json();
        return {...detail, success: true};
      } else {
        return {success: false, error: "This link has expired. Please contact us to obtain a new one."};
      }
    } catch {
      return {success: false, error: "Unable to fetch your quote right now. Please try again later"};
    }
  },
  async expenditureResult(responseId) {
    const magicToken = aipAccessCode();
    await fetch(`/api/aip/${magicToken}/expenditure-questionnaire`, {
      method: "POST",
      body: JSON.stringify({responseId})
    })
  },
  async skipIdUpload() {
    const magicToken = aipAccessCode();
    await fetch(`/api/aip/${magicToken}/identity-document/skip`, {
      method: "POST"
    })
  },
  aipUpload({ accessCode, uploadType, file, name, progress }) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        } else if (xhr.status === 401) {
          signOut();
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({succcess: false}));
      xhr.open("POST", `/api/aip/${accessCode}/${uploadType}?fileName=${file.name}&displayName=${name || file.name}`, true);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  },
  async requestCallback(callbackDateTime) {
    const magicToken = aipAccessCode();
    await fetch(`/api/aip/${magicToken}/request-callback`, {
      method: "POST",
      body: JSON.stringify({when: callbackDateTime})
    })
  },
  async aipPageView() {
    const magicToken = aipAccessCode();
    await fetch(`/api/aip/${magicToken}/view`, {
      method: "POST"
    })
  }
}