import {writable} from "svelte/store";
import jwtDecode from "jwt-decode";

const storedUser = JSON.parse(sessionStorage.getItem("user"));
const sessionUser = storedUser ? {...storedUser, signedIn: true} : {signedIn: false};
export const user = writable(sessionUser);
export const activeCase = writable(null);
export const hideNavigation = writable(false);
export const activeNeeds = writable([]);

export function signIn(u) {
    document.cookie = `access_token=${u.token}; path=/; secure; samesite=strict`;
    const accountId = jwtDecode(u.token).sub.split("@")[0];
    u = {...u, accountId};
    sessionStorage.setItem("user", JSON.stringify(u));
    user.set({...u, signedIn: true});
}

export function signInPreview(u) {
    document.cookie = `access_token=${u.token}; path=/; secure; samesite=strict`;
    sessionStorage.setItem("user", JSON.stringify(u));
    user.set({...u, signedIn: true});
}

export function signOut() {
    sessionStorage.removeItem("user");
    user.set({signedIn: false});
}
