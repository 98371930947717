<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let quote = null;

    $: title = `${quote.name ? `{quote.name}, ` : ``}Upload your documents here!`;
    
    function showUpload(desc, type) {
        dispatch('upload', {
            description: desc,
            type: type
        });
        history.pushState({}, "", "#upload")
    }
    
    function showExpenditure() {
        dispatch('expenditure');
        history.pushState({}, "", "#expenditure");
    }

    function next() {
        dispatch('next');
    }

</script>
<div class="step2">
    <div class="container">
        <h1>{title}</h1>
    </div>
    <div class="tasks">
        <div class="container">
            <div>
                <h2>Income</h2>
                <span>Your payslips and/or bank statements</span>
                {#if quote.proofOfIncomeProvided}
                    <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="green"
                        viewBox="0 0 24 24"
                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                {:else}
                    <button on:click={() => showUpload('Income', 'proof-of-income')}>Upload</button>
                {/if}
            </div>
            <div>
                <h2>Expenditure</h2>
                <span>Tell us what you spend each month</span>
                {#if quote.expenditureQuestionnaireCompleted}
                    <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="green"
                        viewBox="0 0 24 24"
                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                {:else}
                    <button on:click={showExpenditure}>Begin</button>
                {/if}
            </div>
<!--            <div>-->
<!--                <h2>Proof of Identity</h2>-->
<!--                <span>Optional</span>-->
<!--                {#if quote.identityDocumentProvided}-->
<!--                    <svg-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        fill-rule="evenodd"-->
<!--                        clip-rule="evenodd"-->
<!--                        fill="green"-->
<!--                        viewBox="0 0 24 24"-->
<!--                    ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />-->
<!--                    </svg>-->
<!--                {:else}-->
<!--                    <button on:click={() => dispatch('skipId')}>Skip</button>-->
<!--                    <button on:click={() => showUpload('Identity', 'identity-document')}>Upload</button>-->
<!--                {/if}-->
<!--            </div>-->
        </div>
    </div>

    <div class="container next-steps">
        <p>
            <button
                    class="btn get-my-loan"
                    on:click={() => next()}
                    disabled="{!(quote.proofOfIncomeProvided && quote.expenditureQuestionnaireCompleted) ? `disabled` : ``}">
                <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="white"
                        viewBox="0 0 24 24"
                >
                    <path
                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                    />
                </svg>
                <span>Continue</span>
            </button>
        </p>
        <p>No documents to hand? Call us on <strong>01302 590 873</strong>.</p>
    </div>

</div>

<style>

    .tasks {
        background-color: #d5f0f5;
    }

    .tasks > .container {
        display: flex;
        padding: 0;
    }

    .tasks > .container > div {
        flex: 1;
        padding: 0.5em;
        padding-bottom: 1.5em;
        border-left: 1px solid #abe1ec;
    }

    .tasks > .container > div:last-child {
        border-right: 1px solid #abe1ec;
    }

    .tasks > .container > div > span,
    .tasks > .container > div > h2 {
        font-weight: bold;
        color: hsl(190, 64%, 30%);
        display: block;
        margin: 1em 0;
    }

    .tasks > .container > div > p {
        color: #124853;
        font-size: 1.75em;
    }

    .tasks > .container > div > svg {
        width:36px;
        height:36px;
    }

    .next-steps {
        margin-bottom: 2em;
    }

    .next-steps > div {
        margin-top: 2em;
    }

    .btn {
        display: inline-flex;
        border-radius: 4px;
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        border: 1px solid #348f96;
        color: white;
        padding: 0.5em;
        text-decoration: none;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        min-height: 30px;
    }

    .btn > span {
        margin-left: 0.5em;
    }

    .btn[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
        background-image: linear-gradient(to bottom, rgba(154,154,154,1) 0%, rgba(75,75,75,1) 100%);
        border-color: rgba(75,75,75,1);
    }

    @media screen and (max-width: 1236px) {

        .tasks > .container {
            padding: 0;
        }

    }

    @media screen and (min-width: 766px) {

        .get-my-loan {
            padding:.5em 1em;
            font-size:2em;
        }

        .get-my-loan > svg {
            width:36px;
            height:36px;
        }

    }

    @media screen and (max-width: 767px) {

        .get-my-loan {
            font-size: 1.5em;
        }

        .btn {
            display: flex;
            width:100%;
        }

        .tasks > .container {
            flex-direction: column;
        }

        .tasks > .container > div {
            border-left: none;
            border-top: 1px solid #abe1ec;
        }

        .tasks > .container > div:last-child {
            border-bottom: 1px solid #abe1ec;
        }

    }
</style>