<script>
  import {onMount} from "svelte";
  import {router} from "tinro";
  import {signInPreview} from "./store";

  export let accountId;

  onMount(() => {
    const token = location.hash.slice(1);

    const user = {
      token: token,
      profile: {name: "Preview"},
      accountId
    };

    signInPreview(user);
    router.goto("/");
  })
</script>