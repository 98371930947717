<script>
    import {onMount} from "svelte";
    import {router} from "tinro";
    import {user} from "../store";

    let event;

    onMount(() => {
        const queryItems = location.search.slice(1).split("&").map(i => i.split("="));
        const query = {};
        for (const item of queryItems) {
            query[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
        }
        if (query.event !== "signing_complete" && query.event !== "continue_email") {
            router.goto("/documents");
        }
        event = query.event;
    });

    function back() {
        router.goto("/documents");
    }
</script>
<div class="container">
    {#if event === "continue_email"}
        <h1>Please check your e-mail</h1>
        <p>
            We've sent you an e-mail containing a link to sign your document online.
        </p>
        <p>
            All you need to do is tap the link in the e-mail and you will be directed to the signing page.
        </p>
    {:else}
        <h1>Thank You</h1>
        {#if $user.signedIn}
            <p>
                Thanks for signing your documents online. They are now being processed and when complete will be
                available
                for
                you
                to download a signed copy on the <a href="/documents">documents page</a>.
            </p>
            <p>
                <strong>Please note</strong>: If your case is a joint application, the other applicant has been sent
                an
                e-mail
                with instructions on how to sign the document. The document will not be complete until all
                applicants
                have
                signed.
            </p>
        {:else}
            <p>
                Thanks for signing your documents online. We will process them as soon as we can.
            </p>
        {/if}
    {/if}
    {#if $user.signedIn}
        <p>
            <button on:click={back}>&laquo; Back to Loan Documents</button>
        </p>
    {/if}
</div>