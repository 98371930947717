<script>
    import {createEventDispatcher, tick} from 'svelte';
    import {activeCase, activeNeeds} from "../store";

    const dispatch = createEventDispatcher();

    import Uploader from "../components/Uploader.svelte";
    import DocumentNeeds from "./Needs.svelte";

    export let aip = false;
    export let uploadName = "";
    export let uploadType = "default";

</script>

<div className={`container ${$$props.class ? $$props.class : ''}`}>
    <h2>Upload {uploadName ? `${uploadName} ` : ""}Document(s)</h2>
    <p>
        We can accept photos or PDF documents up to 25MB in size,
        and you can upload multiple files at a time.
    </p>
    <Uploader uploadName={uploadName}
              uploadType={uploadType}
              aip={aip}
              on:uploaded={(e) => dispatch('uploaded', e) } />

</div>

