export function formatLoanTerm(term) {
  const years = Math.floor(term / 12);
  const months = term % 12;
  const yearsText = years === 1 ? "1 year" : `${years} years`;
  const monthsText = months === 0 ? "" : months === 1 ? " 1 month" : ` ${months} months`;
  return yearsText + monthsText;
}

export function formatDocumentDate(d) {
  const dt = new Date(d);
  return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()} @ ${dt.getHours() % 12}:${dt.getMinutes() < 10 ? "0" : ""}${dt.getMinutes()}${dt.getHours() < 12 ? "am" : "pm"}`;
}
export function aipAccessCode() {
  if(!window.location.pathname.startsWith("/aip")) return "";
  return window.location.pathname.split("/")[2];
}
