<script>

  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  import api from "../api";
  import {get} from "svelte/store";
  import {activeNeeds} from "../store";
  import {router} from "tinro";
  import NeedsList from "./NeedsList.svelte";

  $: pendingNeeds = $activeNeeds.filter(x => x.completed == false);
  $: completedNeeds = $activeNeeds.filter(x => x.completed == true);

  async function needUploaded(obj) {
    let index = obj.detail.index;
    let e = obj.detail.e;
    let docs = e.detail.completed.map(x => x.doc.id);
    let needs = get(activeNeeds);
    let needId = needs[index].id;
    if (needId != null) {
      await api.needCompleted(needId, docs);
    }
    let needIndex = needs.findIndex(x => x.id == needId);
    needs[needIndex].completed = true;
    activeNeeds.set(needs);
  }

  let working = false;
  let esignUrl = null;

  // eversign events
  window.addEventListener("message", e => {
    if (e.data === "event_loaded") {
    } else if (e.data === "event_signed") {
      esignUrl = null;
      router.goto("/e-sign/complete?event=signing_complete");
    } else if (e.data === "event_declined") {
      esignUrl = null;
    } else if (e.data === "event_error") {
      esignUrl = null;
    }
    working = false;
  });

  async function eSign(obj) {
    let pack = obj.detail.need.pack;
    if (working) return;
    working = true;
    if (pack.id) {
      const result = await api.proceedToSign(pack.id);
      if (result) {
        if (result.redirect) {
          esignUrl = result.redirect;
          return;
        } else {
          router.goto("/e-sign/complete?event=continue_email");
        }
      }
    } else {
      esignUrl = pack.link;
      return;
    }
    working = false;
  }

</script>

{#if esignUrl}
    <div class="esign">
        <iframe title="E-Signatures" src={esignUrl} frameborder="0"></iframe>
        <button class="close" on:click={() => esignUrl = null}>Close</button>
    </div>
{:else }
    <div class="documents-needed">
        {#if pendingNeeds.length }
            <p>Here's a list of the documents we need. Select, upload and move on. You can also upload anything else
                once you've completed your list.</p>
            <NeedsList bind:needs={pendingNeeds} on:uploaded={needUploaded} on:esign={eSign}/>
        {/if}
        {#if completedNeeds.length }
            <h3>Completed</h3>
            <NeedsList bind:needs={completedNeeds}/>
        {/if}
    </div>
{/if}

<div class="working" class:show={working}>
    <svg
            class="loading"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height="4em"
            width="4em"
    >
        <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#2691a6"
                stroke-width="10"
                fill="none"
                stroke-dasharray="226.194 226.194"
        />
    </svg>
    <h2>Please wait...</h2>
</div>

<style>
    :global(.upload) {
        padding: 0;
    }

    .working {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 100;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .working.show {
        display: flex;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .working > svg {
        animation: rotating 2s linear infinite;
    }

    div.esign {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 110;
    }

    div.esign > iframe {
        width: 100%;
        height: 100%;
    }

    div.esign .close {
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 200;
    }

</style>