<script>
    import {onMount} from "svelte";

    import TrustBox from "../components/TrustBox.svelte";
    import Upload from "../components/Upload.svelte";

    import Step1 from "./Step1.svelte";
    import Step2 from "./Step2.svelte";
    import Step3 from "./Step3.svelte";
    import api from "../api";
    import Expenditure from "./Expenditure.svelte";

    export let accessCode;
    let quote;

    onMount(async () => {
        const result = await api.aipStatus(accessCode);
        if (result.success) {
            quote = result;
        }
        window.addEventListener('popstate', statePopped)

        return () => window.removeEventListener('popstate', statePopped)
    });
    
    const statePopped = () => {
        upload.show = false;
        showExpenditure = false;
    }

    let upload = {
        show: false,
        description: "",
        type: ""
    };

    let step = 1;

    let showExpenditure = false;

    function next() {
        step++;
    }

    function showUpload(e) {
        console.log(e);
        upload = {
            description: e.detail.description,
            type: e.detail.type,
            show: true
        };
    }

    function uploadBack() {
        window.history.back();
    }
    
    function uploadComplete() {
        upload.description === "Income"
          ? quote.proofOfIncomeProvided = true
          : quote.identityDocumentProvided = true;
        upload.show = false;
    }
    
    function expenditureComplete() {
        quote.expenditureQuestionnaireCompleted = true;
        showExpenditure = false;
    }
    
    function skipIdUpload() {
        api.skipIdUpload();
        quote.identityDocumentProvided = true;
    }
</script>
{#if quote}
    <div class="aip">
        {#if upload.show}
            <Upload aip
                    bind:uploadName={upload.description}
                    bind:uploadType={upload.type}
                    on:back={uploadBack}
                    on:uploaded={uploadComplete}
            />
        {:else if showExpenditure}
            <Expenditure on:completed={expenditureComplete} />
        {:else}
            {#if step === 1}
                <Step1 bind:quote={quote}
                       on:next={() => next()}
                />
            {:else if step === 2}
                <Step2 bind:quote={quote}
                       on:upload={(e) => showUpload(e)}
                       on:expenditure={() => showExpenditure = true}
                       on:skipId={skipIdUpload}
                       on:next={() => next()}
                />
            {:else if step === 3}
                <Step3 bind:quote={quote}/>
            {/if}
        {/if}

        {#if !upload.show}
            <TrustBox cssClass="padding-top"/>
            <footer>
                <div class="container">
                    <p>
                        This Approved in Principle Quote does not constitute a mortgage offer and
                        does not entitle its recipients to a mortgage advance from the lender.
                        Exact figures and interest rates will be fully explained in your
                        Mortgage Illustration, please ensure you fully read through this once
                        you receive it. Any offer of a mortgage is subject to the prevailing
                        terms and conditions and prior to the lenders full underwriting
                        assessment.
                    </p>
                    <p>
                        <strong
                        >Your home may be repossessed if you do not keep up repayments on
                            your mortgage.</strong
                        >
                    </p>
                </div>
            </footer>
        {/if}

    </div>
{/if}
<style>

    .aip {
        text-align: center;
    }

    footer {
        background-color: #ffffff;
        padding: 1em;
        border-top: 1px solid #cccccc;
    }

</style>