<script>
    import {onMount, tick} from "svelte";

    let trustBox = null;

    async function loadTrustBox() {
        await tick();
        if (window.Trustpilot && trustBox) {
            window.Trustpilot.loadFromElement(trustBox);
        }
    }

    export let cssClass = null;

    onMount(async () => {
        await loadTrustBox();
    });

    // TrustBox
    const trustBoxScript = document.createElement("script");
    trustBoxScript.addEventListener("load", loadTrustBox);
    trustBoxScript.async = true;
    trustBoxScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    document.body.appendChild(trustBoxScript);

</script>

<div class="reviews container {cssClass}">
    <h2>We Believe... Do you? Review our Trustpilot reviews:</h2>
    <!-- TrustBox widget - Carousel -->
    <div
            bind:this={trustBox}
            class="trustpilot-widget"
            ref="trustbox"
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="59a6e7ff0000ff0005aa092e"
            data-style-height="130px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
    >
        <a
                href="https://uk.trustpilot.com/review/believeloans.com"
                target="_blank"
                rel="noopener"
                tinro-ignore
        >Trustpilot</a
        >
    </div>
</div>

<style>

    .padding-top {
        padding-top:1em;
    }

    .reviews {
        padding-bottom: 1.5em;
    }

</style>