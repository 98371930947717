<script>
  import api from "../api";
  import {onMount} from "svelte";
  import {hideNavigation, activeCase} from "../store";

  onMount(async () => {
    hideNavigation.set(true);
    activeCase.subscribe((value) => {
      if (value && value.smartSearch && value.smartSearch.id) {
        api.returnedFromSmartSearch(value.smartSearch.id);
      }
    });
    sessionStorage.setItem("smartSearchComplete", "yes");
  });

</script>

<div class="container">
    <h1>Thank You</h1>
    <p>Thank you for completing your identity verification.</p>
    <a href="/" class="smart-search__complete">My Loan</a>
</div>

<style>

    .container {
        text-align: center;
    }

    a {
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        border: 1px solid #348f96;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: bold;
        padding: 1em 1.5em;
        border-radius: 0.4em;
    }

    a.active, a:hover {
        background-image: linear-gradient(to bottom, #5ce7ff 0%, #47c3cc 100%);
    }

</style>