<script>
    import { router } from "tinro";
</script>

<nav>
    <div class="container">
        <a href="/" class:active={$router.path === "/"}>My Loan</a>
        <a href="/todo" class:active={$router.path === "/todo"}>To-Do List</a>
        <a href="/documents" class:active={$router.path === "/documents"}>My Documents</a>
    </div>
</nav>

<style>
    nav {
        padding-bottom: 1em;
    }

    .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        gap: 1em;
        margin-top: 1em;
    }

    a {
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        border: 1px solid #348f96;
        display: block;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: bold;
        padding: 1em 0;
        border-radius: 0.4em;
    }

    a.active, a:hover {
        background-image: linear-gradient(to bottom, #5ce7ff 0%, #47c3cc 100%);
    }

    @media screen and (max-width: 767px) {
        nav {
            font-size: 75%;
            background-color: #ffffff;
            border-top: 1px solid #cccccc;
            padding: 1em 0;
            background-color: #f0f0f0;
        }

        .container {
            margin: 0;
        }

        a.active, a:hover {
            color: #ffffff;
        }

    }

    @media screen and (min-width: 768px) {

    }
</style>